<template>
    <Header/>
    <Softwareheader/>
    <Softwareservice/>
    <Mobileclient/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Softwareservice from '@/components/softwareservice.vue'
import Mobileclient from '@/components/mobileappclient.vue'
import Softwareheader from '@/components/softwareheader.vue'

export default {
  components: {
     Header,
    Footer,
    Softwareservice,
    Mobileclient,
    Softwareheader
  }
}
</script>