<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Software Testing</h2>
          <p style="padding-top:20px">Our software testers work both manually and using automation tools on various levels to test the efficiency of software applications for all our client’s requirements. These days all our activities have grown so vast in the digital world that we require updated software every minute and hence testing it becomes mandatory to see through its proper functioning. A necessary growth and field of expertise is required for complete product satisfaction and we are a group of talented software testers who can meet all your business requirements.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4>{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Testing.jpg',
        header: 'WHY TEST SOFTWARE',
        caption: 'To render an error-free, perfect software, it is important for the software to be tested. It is a necessary step to be undertaken by every programmer as the tested software could yield maximum results.'
      },
      {
        path: 'services/manual.jpg',
        header: 'QUALITY MANUAL TESTING',
        caption: 'Manual testing of software is done by highly trained and skilled professionals at our company whose aims are client satisfaction and delivering an error free product.'
      },
      {
        path: 'services/automated.jpg',
        header: 'AUTOMATED TESTING',
        caption: 'The automated testing is a cost-effective approach done with pre-written codes to ensure proper functioning of the software. Our qualified tester, familiar with all the testing programmes decides on the perfect testing approach of the product.'
      }
    ]

  })
    
}
</script>
<style>
@media (max-width: 998px) and (min-width: 452px)  {
.services .icon-box h4 {
  font-size: 13px !important;
}
}
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 30px 10px 30px;
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

</style>